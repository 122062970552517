import React from 'react';
import './App.min.css';
import './atropos.css';
import Atropos from 'atropos/react';
import { Cycler } from "react-text-scrambler";
import bg from './i/bg.png';
import cube from './i/cube.png';
import baby from './i/borg-himmleri.png';

const App = () => {
  const strings = ["We are the Borg.", "Lower your shields and surrender your fish", "We will add your biological and technological distinctiveness to our own. ", "Your culture will adapt to service us.", "Resistance is futile!"]
  return (
    <div className="container">
      <Atropos className="borg-banner" highlight={false} shadow={false}>
        <img className="bg-banner-spacer" src={bg} alt="" />
        <img className="cube" data-atropos-offset="-2.5" src={cube} alt="" /> 
        <div className="message" data-atropos-offset="-2.5">
        <Cycler
            duration={ 3000 }
            typewriter={true}
            strings={ strings } />
        </div>
        <img className="baby" data-atropos-offset="1" src={baby} alt="" />
      </Atropos>
    </div>

  );
};
export default App;
